.custom-shape-divider-top-1697630840 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1697630840 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 50px;
}

.custom-shape-divider-top-1697630840 .shape-fill {
  fill: #0F0A70;
}


.custom-shape-divider-top-1697630736 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1697630736 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 50px;
}

.custom-shape-divider-top-1697630736 .shape-fill {
  fill: #FAF8F8;
}

.custom-shape-divider-top-1697630909 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1697630909 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 50px;
}

.custom-shape-divider-top-1697630909 .shape-fill {
  fill: #00AFAB;
}