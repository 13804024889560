/* container do header */
.header {
    background-color: var(--main-white);
    display: flex;
    justify-content: space-around;
    padding: 80px 10px 120px 10px;
}

/* texto do lado */
.text-side {
    width: 552px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: var(--main-purple);
}

.text-side .rastrear {
    display: flex;
    gap: 10px;
    color: var(--main-purple);
    width: 160px;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;
}


.animated-border {
    width: 100%;
    height: 4px;
    background-color: var(--main-purple);
    /* Change to your desired border color */
    animation: borderAnimation 3s linear infinite;
    /* Adjust animation duration as needed */
    position: absolute;
    bottom: 0;

}

.text-side .rastrear h3 {
    color: var(--main-purple);
}

.text-side .rastrear .link {
    color: var(--main-purple);
    text-decoration: none;
    display: flex;
    gap: 10px;
}

.text-side .rastrear :hover {
    color: var(--main-orange);
}

.text-side h1 {
    color: var(--main-purple);
}

.rastreio-img {
    width: 175px;
    height: auto;
    /* To maintain the image's aspect ratio */
    display: block;
    /* Ensures that width and height are applied correctly */
    margin-left: 66px;
    /* Center horizontally */
}

/*container do festival */

.festival {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 80px 0px;
    height: 100%;
    background-color: var(--main-orange);
    align-items: center;
}

.festival:hover {
    cursor: pointer;
}


.texto-festival h1 {
    color: var(--main-white);
    font-size: 48px;
}

.texto-festival h3 {
    color: var(--main-white);
    font-size: 20px;
}

.logo-festival {
    width: 250px;
    height: 270px;
}

/* associação */

.associação {
    background-color: var(--main-white);
    padding: 80px 0px 80px 3px;
    display: flex;
    align-items: center;
}

/* produtores */

.produtores {
    background-color: var(--main-white);
    padding: 80px 0px 80px 3px;
    display: flex;
    align-items: center;
}

/* nao associado */

.nao-associado {
    background-color: var(--main-white);
    padding: 80px 0 80px 0px;
    display: flex;
    align-items: center;
}

.saiba-mais {
    text-decoration: none;
    color: var(--main-purple);
}

.saiba-mais:hover {
    color: var(--main-purple);
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 3px;
}

.ver-mais {
    padding: 10px;
    border-bottom: 3px solid var(--main-white);


}

.ver-mais:hover {
    border-bottom: 3px solid var(--main-purple);
    padding: 10px;
}

/* notícias */

.noticias {
    background-color: var(--main-white);
    display: flex;
    padding: 80px 0px 120px 0px;
    flex-direction: column;
    gap: 64px;
}

.temas {
    color: #0B0B0B;
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

.temas:hover {
    color: #0B0B0B;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    cursor: pointer;
}

@keyframes borderAnimation {
    0% {
        width: 100%;
        right: 0;
    }

    50% {
        width: 0;
    }

    100% {
        width: 100%;
        left: 0;
    }
}

/* media query */

@media (max-width: 768px) {

    .header {
        flex-direction: column;
    }

    .text-side {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    h1 {
        font-size: 24px;
    }

    .festival {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 40px 10px;
        align-items: center;
    }

    .texto-festival {
        text-align: center;

    }

    .texto-festival h1 {
        font-size: 26px;
    }

    .texto-festival h3 {
        font-size: 16px;
    }

    .logo-festival {
        width: 60%;
        height: auto;
        /* To maintain the image's aspect ratio */
        display: block;
        /* Ensures that width and height are applied correctly */
        margin: 0 auto;
        /* Center horizontally */
    }

    .rastreio-img {
        width: 165px;
        height: auto;
        /* To maintain the image's aspect ratio */
        display: block;
        /* Ensures that width and height are applied correctly */
        margin: 0 auto;
        padding-top: 50px;
        /* Center horizontally */
    }

    .produtores {
        padding: 0px
    }

    .associação {
        padding: 10px 0;
    }

    .nao-associado {
        padding: 10px 0;
    }

    .noticias {
        padding: 10px 0;
    }
}