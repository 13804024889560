/* esiilos da pagina de login */
.container-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:64px;
  min-height: 100vh;
  padding: 80px 160px;
  background-color: var(--main-white);
}

/* estilos do formulario */
.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0F0A70;
    gap: 20px;
}

.title h1{
    font-size: 40px;
    font-weight: 900;
}

.title p{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
}

.cpf {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.cpf input {
    color: var(--main-purple);
    width: 100%;
    border: var(--main-purple) 1px solid;
    padding: 15px;
}

.cpf label {
    align-self: flex-start;
    color: var(--main-purple);
}

.senha {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.senha label {
    align-self: flex-start;
    color: var(--main-purple);
}

.senha input {
    color: var(--main-purple);
    width: 100%;
    border: var(--main-purple) 1px solid;
    padding: 15px;
}

.esqueci-senha {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.form .actions-login{
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

 .cadastrar {
    background-color: var(--main-white);
    color: var(--main-purple);
    padding: 15px;
    cursor: pointer;
    border: var(--main-purple) 1px solid;
    font-size: 16px;
    font-weight: 600;
    width: 130px;
}

 .cadastrar:hover {
    background-color: var(--main-purple);
    color: var(--main-white);
}

.entrar {
    background-color: var(--main-purple);
    color: var(--main-white);
    padding: 15px;
    cursor: pointer;
    border: var(--main-purple) 1px solid;
    font-size: 16px;
    width: 130px;
    font-weight: 600;
}

.entrar:hover {
    background-color: var(--main-white);
    color: var(--main-purple);
}